import Helmet from "react-helmet"
import React from "react"
import OptionalOffer from "@tightrope/optionaloffer"
import Optionalofferint from "src/components/modules/optionalofferint"


export default function Optional() {
  const DeData = import("./data/opoffergerman.json");
  const FrData = import("./data/opofferfrench.json");
  module = <OptionalOffer></OptionalOffer>;
  if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
     let lang = navigator.language.slice(0,2);
     if(lang == 'de'){
        module = <Optionalofferint data={DeData} language='de'></Optionalofferint>
      }
      else if(lang == 'fr'){
        module = <Optionalofferint data={FrData} language='fr'></Optionalofferint>
      }
   }
  return(
    <section>
      <Helmet>
      <style type="text/css">{`
        .footer{ position: relative;
          margin-top: 25px;
        }

        .footer a {
          padding: 0px 1px;
        }
    `}</style>
        <body class="white" />
        <title>Optional Offer- Get Freshy Homepage Newtab- freshysearch.com</title>
      </Helmet>
      {module}
    </section>
  )
}
